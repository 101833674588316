import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { fetchingAllSellers, createSeller, updateSeller, deleteSeller } from '../../api/api';
import Sellerform from '../../components/Sellerform';

export default function Seller() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [sellers, setSellers] = useState([]);
  const [formData, setFormData] = useState({
    seller: '',
    username: localStorage.getItem('username')
  });
  const [selectedSeller, setSelectedSeller] = useState(null);

  useEffect(() => {
    fetchSellers();
  }, []);

  const fetchSellers = async () => {
    try {
      const sellers = await fetchingAllSellers();
      console.log(sellers);
      setSellers(sellers);
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createSeller(formData);
      fetchSellers();
      toast.success('Seller added successfully.!');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes('username')) {
          toast.warn('Seller with this namealready exists.');
        }
      } else {
        toast.error('Error adding Seller. Please try again.');
      }
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSeller(selectedSeller.id, formData);
      fetchSellers();
      toast.success('Seller updated successfully.');
      setSelectedSeller(null);
    } catch (error) {
      toast.error('Error updating Seller. Please try again.');
    }
  };

  const handleUpdate = (seller) => {
    setSelectedSeller(seller);
    setFormData({
      seller: seller.seller,
      amount: seller.amount
   });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Seller?");
    if (isConfirmed) {
      try {
        await deleteSeller(userId);
        fetchSellers();
        toast.success('Seller deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Seller. Please try again.');
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>
            <div className='float-end'>
                <Sellerform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
            </div>

            <h6><u>Sellers Dashboard</u></h6>
            <table className="table table-striped">
            <thead>
              <tr>
                <td><strong>#</strong></td>
                <th>Name</th>
                <th>Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sellers.map((seller, index) => (
                <tr key={seller.id}>
                  <td>{index + 1}</td>
                  <td>{seller.sellername}</td>
                  <td>{seller.createdAt}</td>
                  <td>      
                    <Link to={`/sellerdetails/${seller.id}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>              
                    <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(seller.id)} style={{ paddingLeft: '10px'}}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ToastContainer />
          </div>
          }
          
          
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
