import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Taskform from '../../components/Taskform';
import { fetchAllTasks, createTask, updateTask, deleteTask } from '../../api/api';

export default function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [formData, setFormData] = useState({
    task: '',
    status: '',
    priority: '',
    due: '',
    userid: localStorage.getItem('userid'),
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [expandedTasks, setExpandedTasks] = useState({}); // Track which tasks are expanded

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const tasks = await fetchAllTasks(localStorage.getItem('userid'));
      setTasks(tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createTask(formData);
      fetchTasks();
      toast.success('Task added successfully!');
    } catch (error) {
      toast.error('Error adding task. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateTask(selectedTask.id, formData);
      fetchTasks();
      toast.success('Task updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating task. Please try again.');
    }
  };

  const handleUpdate = (task) => {
    setSelectedTask(task);
    setFormData({
      task: task.task,
      status: task.status,
      priority: task.priority,
      due: task.due,
    });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this task?");
    if (isConfirmed) {
      try {
        await deleteTask(userId);
        fetchTasks();
        toast.success('Task deleted successfully.');
      } catch (error) {
        toast.error('Error deleting task. Please try again.');
      }
    }
  };

  const toggleExpand = (taskId) => {
    setExpandedTasks((prevState) => ({
      ...prevState,
      [taskId]: !prevState[taskId], // Toggle expanded state for the task
    }));
  };

  return (
    <>
      <div className='container mt-5'>
        <button className='btn btn-success btn-sm float-end' data-bs-toggle="modal" data-bs-target="#exampleModal">Create Task</button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title fs-5" id="exampleModalLabel">Add Task</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Taskform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <h5><u className='titilescolor'>Tasks</u></h5>

        <div className='row'>
          {tasks.map((task) => (
            <div className='col-md-4 mt-3' key={task.id}>
              <div className={`card ${task.priority === 'low' ? 'low-priority' : task.priority === 'meduim' ? 'meduim-priority' : 'high-priority'}`}>
                <div className="card-body">
                  <h6>{expandedTasks[task.id] ? task.task : task.task.length > 30 ? `${task.task.substring(0, 30)}...` : task.task} {task.task.length > 30 && (
                    <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => toggleExpand(task.id)}>
                      {expandedTasks[task.id] ? 'See Less' : 'See More'}
                    </span>
                  )}</h6> {/* Truncate if task is too long */}
                  
                  <h6>State: {task.status}</h6>
                  <h6>Priority: {task.priority}</h6>
                  <h6>Due: {task.due}</h6>
                  <h6>Created: {task.createdAt}</h6>
                  <i className="fa-solid fa-pen-to-square fa-1x editicon" onClick={() => handleUpdate(task)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Task</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Taskform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(task.id)} style={{ paddingLeft: '10px'}}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
