// UserForm.js
import React from 'react';

const Customerform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-1">
        <label htmlFor="name" className="form-label">Name</label>
        <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
      </div>
      <div className="mb-1">
        <label htmlFor="phone" className="form-label">Phone</label>
        <input type="number" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
      </div>
      <div className="mb-1">
        <label htmlFor="customertype" className="form-label">Customer Type</label>
        <select className="form-select" id="customertype" name="type" value={formData.type} onChange={handleChange} required>
          <option disabled value="">Select Type</option>
          <option value="retail">Retail</option>
          <option value="wholesale">Wholesale</option>
        </select>
      </div>
      {/* hidden from user  */}
      <div className="mb-1 d-none">
        <label htmlFor="designation" className="form-label">Designation</label>
        <input type="text" className="form-control" id="designation" name="designation" value={formData.designation} onChange={handleChange} />
      </div>

      <div className="mb-1">
        <label htmlFor="email" className="form-label">Email</label>
        <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
      </div>     
      <div className="mb-1">
        <label htmlFor="credit" className="form-label">Credit Limit</label>
        <input type="number" className="form-control" id="credit" name="credit_limit" value={formData.credit_limit} onChange={handleChange} required />
      </div>
      <div className="mb-1">
        <label htmlFor="password" className="form-label">Password</label>
        <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleChange} required />
      </div>
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Customerform;
