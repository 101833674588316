import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';

const SidePanel = ({ isOpen, toggleSidePanel, handleMenuItemClick }) => {
  const { user } = useUser();
  const designation = localStorage.getItem('designation'); // Get designation from local storage
  const [displayUsername, setDisplayUsername] = useState(true);
  const [sidepselected, setSidepselected] = useState(null);

  const handleItemClick = (index) => {
    setSidepselected(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayUsername(false);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div>
        <div className={`side-panel ${isOpen ? 'open' : ''}`}>
          <div className="panel-content">
            {displayUsername && user && (
              <div></div>
            )}
            {isOpen ? (
              <>
                <h6 className={`sidep ${sidepselected === 1 ? 'selected' : ''}`} onClick={() => {handleMenuItemClick('profile'); handleItemClick(1)}}>
                  <i className="sidepicons fa-solid fa-address-card mt-3"></i>
                  <span className='mt-3' style={{ marginLeft: '14px' }}>Profile</span>
                </h6>
                <h6 className={`sidep ${sidepselected === 2 ? 'selected' : ''}`} onClick={() => {handleMenuItemClick('orders'); handleItemClick(2)}}>
                  <i className="sidepicons fa-solid fa-layer-group mt-3"></i>
                  <span className='mt-3' style={{ marginLeft: '14px' }}>Orders</span>
                </h6>

                {designation !== 'customer' && (
                  <div>
                    <h6 className={`sidep ${sidepselected === 3 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('dashboard'); handleItemClick(3)}}>
                      <i className="sidepicons fa-solid fa-chess-board mt-3"></i>
                      <span className='mt-3' style={{ marginLeft: '20px' }}>Dashboard</span>
                    </h6>
                    <h6 className={`sidep ${sidepselected === 4 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('users'); handleItemClick(4)}}>
                      <i className="sidepicons fa-solid fa-users mt-2"></i>
                      <span className='mt-2' style={{ marginLeft: '10px' }}>Users</span>
                    </h6>
                    <h6 className={`sidep ${sidepselected === 5 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('customers'); handleItemClick(5)}}>
                      <i className="sidepicons fa-solid fa-person mt-2"></i>
                      <span className='mt-2' style={{ marginLeft: '25px' }}>Customers</span>
                    </h6>
                    <h6 className={`sidep ${sidepselected === 6 ? 'selected' : ''}`} onClick={() => {handleMenuItemClick('tasks'); handleItemClick(6)}}>
                      <i className="sidepicons fa-solid fa-list-check mt-2"></i>
                      <span className='mt-2' style={{ marginLeft: '18px' }}>Tasks</span>
                    </h6>
                    <h6 className={`sidep ${sidepselected === 7 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('notes'); handleItemClick(7)}}>
                      <i className="sidepicons fa-solid fa-note-sticky mt-2"></i>
                      <span className='mt-2' style={{ marginLeft: '20px' }}>Notes</span>
                    </h6>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className='text-center'>
                  <h6  className={`sidep ${sidepselected === 1 ? 'selected' : ''}`} onClick={() => {handleMenuItemClick('profile'); handleItemClick(1)}}>
                    <i className="sidepicons fa-solid fa-address-card mt-3"></i>
                  </h6>
                  <h6 className={`sidep ${sidepselected === 2 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('orders'); handleItemClick(2)}}>
                    <i className="sidepicons fa-solid fa-layer-group mt-3"></i>
                  </h6>

                  {designation !== 'customer' && (
                    <div>
                      <h6 className={`sidep ${sidepselected === 3 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('dashboard'); handleItemClick(3)}}>
                        <i className="sidepicons fa-solid fa-chess-board mt-3"></i>
                      </h6>
                      <h6 className={`sidep ${sidepselected === 4 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('users'); handleItemClick(4)}}>
                        <i className="sidepicons fa-solid fa-users mt-2"></i>
                      </h6>
                      <h6 className={`sidep ${sidepselected === 5 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('customers'); handleItemClick(5)}}>
                        <i className="sidepicons fa-solid fa-person mt-2"></i>
                      </h6>
                      <h6 className={`sidep ${sidepselected === 6 ? 'selected' : ''}`}  onClick={() => {handleMenuItemClick('tasks'); handleItemClick(6)}}>
                        <i className="sidepicons fa-solid fa-list-check mt-2"></i>
                      </h6>
                      <h6 className={`sidep ${sidepselected === 7 ? 'selected' : ''}`} onClick={() => {handleMenuItemClick('notes'); handleItemClick(7)}}>
                        <i className="sidepicons fa-solid fa-note-sticky mt-2"></i>
                      </h6>
                    </div>
                  )}

                </div>
              </>
            )}
          </div>
          {/* Button to toggle side panel */}
          <div className="toggle-btn" onClick={toggleSidePanel}>
            {isOpen ? <i className="fa-solid fa-arrow-left fa-xl" style={{color: 'red'}}></i> : <i className="fa-solid fa-arrow-right fa-xl" style={{color: 'green'}}></i>}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanel;
