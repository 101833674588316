import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { Link } from 'react-router-dom';
import { fetchingAllCommisions } from '../../api/api';

export default function Commisions() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [sellers, setSellers] = useState([]);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  const fetchSellers = async () => {
    try {
      const sellersData = await fetchingAllCommisions();
      console.log('the data', sellersData)

      // Filter sellers to include only those created today
      const today = new Date();
      const startOfDay = new Date(today.setHours(0, 0, 0, 0)).toISOString();
      const endOfDay = new Date(today.setHours(23, 59, 59, 999)).toISOString();

      const filteredSellers = sellersData.filter(seller => {
        const createdAt = new Date(seller.createdAt).toISOString();
        return createdAt >= startOfDay && createdAt <= endOfDay;
      });

      // Group by sellerid and calculate total commission
      const groupedSellers = new Map();
      filteredSellers.forEach(seller => {
        if (groupedSellers.has(seller.sellerid)) {
          groupedSellers.get(seller.sellerid).totalCommission += seller.commision;
        } else {
          groupedSellers.set(seller.sellerid, {
            ...seller,
            totalCommission: seller.commision,
          });
        }
      });

      // Convert Map to array for rendering
      setSellers(Array.from(groupedSellers.values()));
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  // Calculate the subtotal for commissions
  const subtotal = sellers.reduce((acc, seller) => acc + seller.totalCommission, 0);

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>
            <h5><u className='titilescolor'>Today's Commissions</u></h5>

            <table className="table table-striped">
              <thead>
                <tr className='theads'>
                  <td><strong>#</strong></td>
                  <th>Name</th>
                  <th>Commission</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sellers.map((seller, index) => (
                  <tr key={seller.id}>
                    <td>{index + 1}</td>
                    <td>{seller.seller ? seller.seller.sellername : 'unknown'}</td>
                    <td>{Number(seller.totalCommission).toLocaleString()}</td>
                    <td>{new Date(seller.createdAt).toLocaleString()}</td>
                    <td>      
                      <Link to={`/commisiondetails/${seller.seller ? seller.seller.sellername : 'unknown'}`}>
                        <i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px' }}></i>
                      </Link>              
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className='text-success' colSpan="2"><strong>Subtotal</strong></td>
                  <td className='text-success'><strong>{Number(subtotal).toLocaleString()}</strong></td>
                  <td colSpan="2"></td>
                </tr>
              </tfoot>
            </table>
          </div>}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
