import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notesform from '../../components/Notesform';
import { fetchingAllNotes, createNote, updateNote, deleteNote } from '../../api/api';

export default function Notes() {
  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState({
    note: '',
    title: '',
    userid: localStorage.getItem('userid')
  });
  const [selectedNote, setSelectedNote] = useState(null);
  const [expandedNotes, setExpandedNotes] = useState({}); // Track which notes are expanded

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const notes = await fetchingAllNotes(localStorage.getItem('userid'));
      console.log(notes);
      setNotes(notes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createNote(formData);
      fetchNotes();
      toast.success('Note added successfully!');
    } catch (error) {
      toast.error('Error adding note. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateNote(selectedNote.id, formData);
      fetchNotes();
      toast.success('Note updated successfully.');
      setSelectedNote(null);
    } catch (error) {
      toast.error('Error updating note. Please try again.');
    }
  };

  const handleUpdate = (note) => {
    setSelectedNote(note);
    setFormData({
      note: note.note,
      title: note.title,
    });
  };

  const handleDelete = async (noteId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this note?");
    if (isConfirmed) {
      try {
        await deleteNote(noteId);
        fetchNotes();
        toast.success('Note deleted successfully.');
      } catch (error) {
        toast.error('Error deleting note. Please try again.');
      }
    }
  };

  const toggleExpand = (noteId) => {
    setExpandedNotes((prevState) => ({
      ...prevState,
      [noteId]: !prevState[noteId], // Toggle expanded state for the note
    }));
  };

  return (
    <>
      <div className='container mt-5'>
        <button className='btn btn-success btn-sm float-end' data-bs-toggle="modal" data-bs-target="#exampleModal">Create Note</button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title fs-5" id="exampleModalLabel">Add Note</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Notesform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <h5><u className='titilescolor'>Notes</u></h5>
        <div className='row'>
          {notes.map((note) => (
            <div className='col-md-4 mt-3' key={note.id}>
              <div className="card">
                <div className="card-body">
                  <h6>Title: {note.title}</h6>
                  <div
                    className={`note-content ${expandedNotes[note.id] ? 'note-expanded' : 'note-collapsed'}`}
                  >
                    <p>
                      {note.note}
                    </p>
                  </div>
                  {note.note.length > 30 && (
                    <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => toggleExpand(note.id)}>
                      {expandedNotes[note.id] ? ' See Less' : ' See More'}
                    </span>
                  )}
                  <h6>Created: {note.createdAt}</h6>
                  <i className="fa-solid fa-pen-to-square fa-1x editicon" onClick={() => handleUpdate(note)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Note</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Notesform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(note.id)} style={{ paddingLeft: '10px' }}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
