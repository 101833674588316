import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { fetchAllSales } from '../../api/api';

export default function Salesreport() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [customerfilter, setCustomerFilter] = useState('all');
  const [labelfilter, setLabelFilter] = useState('all');
  const [label, setLabels] = useState([]);
  const [itemFilter, setItemFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [customers, setCustomers] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [usdRate, setUsdRate] = useState(1);

  // Get today's date in the format 'YYYY-MM-DD'
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const [startDate, setStartDate] = useState(getTodayDate()); // Default to today's date
  const [endDate, setEndDate] = useState(getTodayDate()); 

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    filterItems();
  }, [items, customerfilter, labelfilter, itemFilter, startDate, endDate, usdRate, searchTerm]);

  const fetchItems = async () => {
    try {
      const items = await fetchAllSales();
      // setItems(items);
      // setFilteredItems(items);

      // items.forEach(item => {
      //   console.log(`Item ID: ${item.id}, saleqty: ${item.saleqty}, cnqty: ${item.cnqty}`);
      // });

      // Filter items where either saleqty or cnqty is greater than 0, and saleqty is not equal to cnqty
      const filteredItems = items.filter(item => {
        const validQty = (item.saleqty > 0 || item.cnqty > 0) && item.saleqty !== item.cnqty;
        return validQty;
      });

      console.log(filteredItems)

      setFilteredItems(filteredItems);
      setItems(filteredItems);

      const uniqueItems = [...new Set(items.map(item => item.itemid.item))];
      setAllItems(uniqueItems);

      const uniqueCustomers = [...new Set(items.map(item => item.customer))];
      setCustomers(uniqueCustomers);

      const uniqueLabels = [...new Set(items.map(item => item.label))];
      setLabels(uniqueLabels);

    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const filterItems = () => {
    let filtered = items;

    if (customerfilter !== 'all') {
      filtered = filtered.filter(item => item.customer === customerfilter);
    }

    if (labelfilter !== 'all') {
      filtered = filtered.filter(item => item.label === labelfilter);
    }

    if (itemFilter !== 'all') {
      filtered = filtered.filter(item => item.itemid.item === itemFilter);
    }

    if (startDate) {
      filtered = filtered.filter(item => {
        const itemDate = item.createdAt.split(' ')[0]; // Extract just the 'YYYY-MM-DD' part
        return new Date(itemDate) >= new Date(startDate);
      });
    }
  
    if (endDate) {
      filtered = filtered.filter(item => {
        const itemDate = item.createdAt.split(' ')[0]; // Extract just the 'YYYY-MM-DD' part
        return new Date(itemDate) <= new Date(endDate);
      });
    }

    if (searchTerm) {
      filtered = filtered.filter(item => 
        item.itemid.item.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredItems(filtered);
  };

  const handleCustomerFilterChange = (event) => {
    setCustomerFilter(event.target.value);
  };

  const handleLabelFilterChange = (event) => {
    setLabelFilter(event.target.value);
  };

  const handleItemFilterChange = (event) => {
    setItemFilter(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleUsdRateChange = (event) => {
    setUsdRate(event.target.value);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const calculateSubtotal = () => {
    return filteredItems.reduce((total, item) => total + item.saletotal, 0);
  };

  const calculateKSHPTotal = () => {
    return filteredItems.reduce((total, item) => total + (item.saleprice - item.commision), 0);
  };

  const calculateQTYsub = () => {
    return filteredItems.reduce((total, item) => total + (item.saleqty - item.cnqty), 0);
  };

  const calculateKSHSUBTotal = () => {
    return filteredItems.reduce((total, item) => total + item.saletotal, 0);
  };

  const calculateUsdSubtotal = () => {
    return filteredItems.reduce((total, item) => total + (item.saletotal / usdRate), 0);
  };

  const calculateUsdSubtotalprice = () => {
    return filteredItems.reduce((total, item) => total + ((item.saleprice - item.commision) / usdRate), 0);
  };

  const csvHeaders = [
    { label: "Seller", key: "seller" },
    { label: "Customer", key: "customer" },
    { label: "Label", key: "label" },
    { label: "Price", key: "saleprice" },
    { label: "USD Price", key: "usdSalePrice" },
    { label: "QTY", key: "saleqty" },
    { label: "Total", key: "saletotal" },
    { label: "USD Total", key: "usdSaleTotal" },
    { label: "Item", key: "itemid.item" },
    { label: "Created", key: "createdAt" }
  ];

  // Group items by label
  const groupItemsByLabel = () => {
    const grouped = {};
    filteredItems.forEach(item => {
      if (!grouped[item.label]) {
        grouped[item.label] = [];
      }
      grouped[item.label].push({
        ...item,
        usdSalePrice: (item.saleprice / usdRate).toFixed(2),
        usdSaleTotal: (item.saletotal / usdRate).toFixed(2)
      });
    });
    return grouped;
  };

  // Flatten grouped data for CSV export
  const prepareCSVData = () => {
    const grouped = groupItemsByLabel();
    const csvData = [];
    Object.keys(grouped).forEach(label => {
      csvData.push({ label: `--- ${label} ---` }); // Label header
      csvData.push(...grouped[label]);
    });
    return csvData;
  };

  // const csvHeaders = [
  //   { label: "Seller", key: "seller" },
  //   { label: "Customer", key: "customer" },
  //   { label: "Label", key: "label" },
  //   { label: "Price", key: "saleprice" },
  //   { label: "USD Price", key: "usdSalePrice" },
  //   { label: "QTY", key: "saleqty" },
  //   { label: "Total", key: "saletotal" },
  //   { label: "USD Total", key: "usdSaleTotal" },
  //   { label: "Item", key: "item.itemid.item" },
  //   { label: "Created", key: "createdAt" }
  // ];

  // // Group items by label
  // const groupItemsByLabel = () => {
  //   const grouped = {};
  //   filteredItems.forEach(item => {
  //     if (!grouped[item.label]) {
  //       grouped[item.label] = [];
  //     }
  //     grouped[item.label].push({
  //       ...item,
  //       usdSalePrice: (item.saleprice / usdRate).toFixed(2),
  //       usdSaleTotal: (item.saletotal / usdRate).toFixed(2)
  //     });
  //   });
  //   return grouped;
  // };

  // const prepareCSVData = () => {
  //   const grouped = groupItemsByLabel();
  //   const csvData = [];
  
  //   let grandTotalSaleQty = 0;
  //   let grandTotalSaleTotal = 0;
  //   let grandTotalUsdSaleTotal = 0;
  
  //   Object.keys(grouped).forEach(label => {
  //     // Add label header
  //     csvData.push({ label: `--- ${label} ---` });
  
  //     let totalSaleQty = 0;
  //     let totalSaleTotal = 0;
  //     let totalUsdSaleTotal = 0;
  
  //     // Map each item with consistent calculations for CSV export
  //     csvData.push(...grouped[label].map(item => {
  //       const adjustedSaleQty = item.saleqty - item.cnqty;
  //       const adjustedSaleTotal = (adjustedSaleQty * (item.saleprice - item.commision)).toFixed(2);
  //       const adjustedUsdSaleTotal = (adjustedSaleTotal / usdRate).toFixed(2);
  
  //       // Add to group totals
  //       totalSaleQty += adjustedSaleQty;
  //       totalSaleTotal += parseFloat(adjustedSaleTotal);
  //       totalUsdSaleTotal += parseFloat(adjustedUsdSaleTotal);
  
  //       // Add to grand totals
  //       grandTotalSaleQty += adjustedSaleQty;
  //       grandTotalSaleTotal += parseFloat(adjustedSaleTotal);
  //       grandTotalUsdSaleTotal += parseFloat(adjustedUsdSaleTotal);
  
  //       return {
  //         seller: item.seller,
  //         customer: item.customer,
  //         label: item.label,
  //         saleprice: Number(item.saleprice - item.commision).toFixed(2), // KSH.P as in the table
  //         usdSalePrice: ((item.saleprice - item.commision) / usdRate).toFixed(2), // USD.P as in the table
  //         saleqty: adjustedSaleQty, // Adjusted QTY
  //         saletotal: adjustedSaleTotal, // KSH.T as in the table
  //         usdSaleTotal: adjustedUsdSaleTotal, // USD.T as in the table
  //         item: item.itemid.item,
  //         createdAt: item.createdAt
  //       };
  //     }));
  
  //     // Add subtotal row for the current label group
  //     csvData.push({
  //       seller: '',
  //       customer: '',
  //       label: `${label} Totals`,
  //       saleprice: '',
  //       usdSalePrice: '',
  //       saleqty: totalSaleQty,
  //       saletotal: totalSaleTotal.toFixed(2),
  //       usdSaleTotal: totalUsdSaleTotal.toFixed(2),
  //       item: '',
  //       createdAt: ''
  //     });
  //   });
  
  //   // Add final grand total row for all items
  //   csvData.push({
  //     seller: '',
  //     customer: '',
  //     label: 'Grand Total',
  //     saleprice: '',
  //     usdSalePrice: '',
  //     saleqty: grandTotalSaleQty,
  //     saletotal: grandTotalSaleTotal.toFixed(2),
  //     usdSaleTotal: grandTotalUsdSaleTotal.toFixed(2),
  //     item: '',
  //     createdAt: ''
  //   });
  
  //   return csvData;
  // };   

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && (
            <div className='mt-4'>
              <h5><u className='titilescolor'>Sales Report</u></h5>
              <div className='d-flex justify-content-between'>
                <div className='mt-3'>
                  <input
                    type='number'
                    className='form-control d-inline-block w-auto me-2'
                    placeholder='USD Rate'
                    value={usdRate}
                    onChange={handleUsdRateChange}
                  />
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                  <select className='form-select d-inline-block w-auto' value={customerfilter} onChange={handleCustomerFilterChange}>
                    <option value='all'>All Customers</option>
                    {customers.map((customer, index) => (
                      <option key={index} value={customer}>{customer}</option>
                    ))}
                  </select>

                  <select className='form-select d-inline-block w-auto mx-2' value={labelfilter} onChange={handleLabelFilterChange}>
                    <option value='all'>Labels</option>
                    {label.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>

                  <select className='form-select d-inline-block w-auto' value={itemFilter} onChange={handleItemFilterChange}>
                    <option value='all'>All Items</option>
                    {allItems.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                  <input
                    type='text'
                    className='form-control d-inline-block w-auto me-2'
                    placeholder='Search by item'
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                  <CSVLink 
                    data={prepareCSVData()}
                    headers={csvHeaders}
                    filename={"sales-report.csv"}
                    className="btn btn-success btn-sm ms-2 mt-2"
                  >
                    Export to CSV
                  </CSVLink>
                </div>
              </div>
              <div className='row text-center mt-4'>
                <table className="table table-striped">
                  <thead>
                    <tr className='theads'>
                      <td><strong>#</strong></td>
                      <th>Item</th>
                      <th>QTY</th>
                      <th>KSH.P</th>
                      <th>KSH.T</th>
                      <th>USD.P</th>
                      <th>USD.T</th>
                      <th>Label</th>
                      <th>Customer</th>
                      <th>Seller</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.itemid.item}</td>
                        <td>{item.saleqty - item.cnqty}</td>
                        <td className='text-success'>{Number(item.saleprice - item.commision).toLocaleString()}</td>
                        <td className='text-success'>{Number((item.saleqty - item.cnqty) * (item.saleprice - item.commision)).toLocaleString()}</td>
                        <td className='text-danger'>{((item.saleprice - item.commision) / usdRate).toFixed(2)}</td>
                        <td className='text-danger'>{(((item.saleqty - item.cnqty) * (item.saleprice - item.commision) / usdRate)).toFixed(2)}</td>
                        <td>{item.label}</td>
                        <td>{item.customer}</td>
                        <td>{item.seller}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className='text-success'><strong>Subtotal</strong></td>
                      <td colSpan="1"></td>
                      <td className='text-success'><strong>{calculateQTYsub()}</strong></td>
                      <td className='text-success'><strong>{Number(calculateKSHPTotal()).toLocaleString()}</strong></td>
                      <td className='text-success'><strong>{Number(calculateKSHSUBTotal()).toLocaleString()}</strong></td>
                      <td className='text-danger'><strong>{calculateUsdSubtotalprice().toFixed(2)}</strong></td>
                      <td className='text-danger'><strong>{calculateUsdSubtotal().toFixed(2)}</strong></td>
                      <td colSpan="5"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
