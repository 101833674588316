// src/pages/SolditemsDetails.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import PrintLoadList from '../components/PrintLoadList';
import PrintInvoice from '../components/PrintInvoice';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchsalesDetails, fetchingAllSellers, fetchingAllPayments, deletePayment, deleteInvoice, creditNoteItem, updatemakeSale, createPayment, fetchingComplains } from '../api/api';
import ReactToPrint from 'react-to-print';

export default function SolditemsDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [sellers, setSellers] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [bank, setBank] = useState('');
  const [mpesa, setMpesa] = useState('');
  const [cash, setCash] = useState('');
  const [etrNumber, setEtrNumber] = useState('');
  const [commission, setCommission] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [payments, setPayments] = useState('');
  const [paymentmethod, setPaymentmethod] = useState('');
  const [commissions, setCommissions] = useState({});
  const [complains, setComplains] = useState([]);
  const [totalGoodComplains, setTotalGoodComplains] = useState(0);

  const calculateTotalGoodComplains = () => {
    const total = complains.reduce((acc, item) => acc + (item.amount * item.qty), 0);
    setTotalGoodComplains(total);
  };

  // Call the function when complains change
  useEffect(() => {
    calculateTotalGoodComplains();
  }, [complains]);


  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    sale: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedLabel, setSelectedTask] = useState(null);

  const printRefLoadList = useRef(); // Ref for the Load List component
  const printRefInvoice = useRef(); // Ref for the Invoice component

  useEffect(() => {
    fetchLabelItems();
    fetchSellers();
    fetchPayments();
    fetchComplainsForthissale()
  }, []);

  const fetchComplainsForthissale = async () => {
    try {
      const complains = await fetchingComplains();
      // Filter complains where itemsaleid matches the provided id
      const filteredComplains = complains.filter(complain => complain.itemsaleid === id);
      console.log('Filtered complains', filteredComplains);

      setComplains(filteredComplains);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchLabelItems = async () => {
    try {
      const sales = await fetchsalesDetails(id);
      console.log('sales', sales);
      setSales(sales);
      if (sales.length > 0) {
        // Set initial values for form inputs
        setInvoiceNumber(sales[0].invoicenumber || '');
        setBank(sales[0].bank || 0);
        setMpesa(sales[0].mpesa || 0);
        setCash(sales[0].cash || 0);
        setEtrNumber(sales[0].etrnumber || '');
      }
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchSellers = async () => {
    try {
      const sellers = await fetchingAllSellers();
      setSellers(sellers);
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  const fetchPayments = async () => {
    try {
      const payments = await fetchingAllPayments();
      const filteredPayments = payments.filter(payment => payment.saleid === id);
      console.log('payments', filteredPayments, id)
      setPayments(filteredPayments);
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
  };

  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleBankChange = (e) => {
    setBank(e.target.value);
  };

  const handleMpesaChange = (e) => {
    setMpesa(e.target.value);
  };

  const handleCashChange = (e) => {
    setCash(e.target.value);
  };

  const handleEtrNumberChange = (e) => {
    setEtrNumber(e.target.value);
  };

  const handleCommissionChange = (e, saleId) => {
    const { value } = e.target;
    setCommissions({ ...commissions, [saleId]: parseFloat(value) || 0 });

    // Update the saletotal based on the new commission
    setSales(sales.map(sale => {
      if (sale.id === saleId) {
        const newTotal = sale.saleprice * sale.saleqty;
        const totalCommission = parseFloat(value) * sale.saleqty;
        return { ...sale, saletotal: newTotal - totalCommission };
      }
      return sale;
    }));
  };

  const handleSellerIdChange = (e) => {
    setSellerId(e.target.value);
  };

  const totalAmount = sales.reduce((total, sale) => total + ((sale.saleqty - (sale.cnqty || 0)) * sale.saleprice), 0);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredItems = sales.filter(item =>
    item.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCNQtyChange = (e, index) => {
    const updatedSales = [...sales];
    updatedSales[index].cnqty = parseInt(e.target.value, 10);
    updatedSales[index].saletotal = (updatedSales[index].saleqty - updatedSales[index].cnqty) * updatedSales[index].saleprice;
    setSales(updatedSales);
  };

  const handleSell = async (invoiceNumber, bank, mpesa, cash, etrNumber, commission, sellerId, paymentmethod, totalCommission) => {
    try {
      const formData = {
        // Include invoice number, ETR number, commission, and seller ID in the request payload
        invoiceNumber,
        bank, 
        mpesa, 
        cash,
        etrNumber,
        commission,
        paymentmethod,
        sellerId,
        saleId: id,
        totalCommission 
      };

      // console.log(formData)
      // Call the makeSale API with the updated formData
      await updatemakeSale(formData);
      await createPayment(formData)

      navigate('/invoices');
    } catch (error) {
      toast.error('Error when selling. Please try again.');
    }
  };

  const handleSellClick = (invoiceNumber, etrNumber, commission, sellerId, paymentmethod) => {
    const totalCommission = sales.reduce((total, sale) => total + (commissions[sale.id] ? commissions[sale.id] * sale.saleqty : 0), 0);
    handleSell(invoiceNumber, etrNumber, commission, sellerId, paymentmethod, totalCommission);
  };

  const handleSubmit = async (sale) => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to submit the credit note item?');
      if (isConfirmed) {
        const creditNoteData = {
          itemId: sale.itemid.id,
          saleQty: sale.saleqty,
          saleId: sale.id,
          cnqty: sale.cnqty,
          salePrice: sale.saleprice,
          saletotal: sale.saletotal,
          saleType: sale.saletype,
          saleStatus: sale.salestatus,
          customer: sale.customer,
          balereturndate: new Date().toISOString()  // Add current date here
        };

        const response = await creditNoteItem(creditNoteData);
        toast.success('Credit note item submitted successfully');
      }
    } catch (error) {
      toast.error('Error submitting credit note item');
    }
  };
  // Convert bank, mpesa, and cash to numbers
  const bankAmount = parseFloat(bank) || 0;
  const mpesaAmount = parseFloat(mpesa) || 0;
  const cashAmount = parseFloat(cash) || 0;

  // const balance = sales.length > 0 && sales[0].bank !== undefined && sales[0].mpesa !== undefined && sales[0].cash !== undefined ? totalAmount - (sales[0].bank + sales[0].mpesa + sales[0].cash + totalGoodComplains) : 0;
  const balance = totalAmount - (bankAmount + mpesaAmount + cashAmount + totalGoodComplains);

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Payment?");
    if (isConfirmed) {
      try {
        await deletePayment(id);
        fetchLabelItems();
        fetchSellers();
        fetchPayments();
        fetchComplainsForthissale()
        toast.success('Payment deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Payment. Please try again.');
      }
    }
  };

  const handleDeleteInvoice = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this INVOICE?");
    if (isConfirmed) {
      try {
        await deleteInvoice(id);
        toast.success('INVOICE deleted successfully.');
        navigate('/invoices');

      } catch (error) {
        toast.error('Error deleting INVOICE. Please try again.');
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={() => setIsSidePanelOpen(!isSidePanelOpen)}
          handleMenuItemClick={setActiveScreen}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && 
            <div className='mt-4'>
              
              <ReactToPrint
                trigger={() => <button className='btn btn-sm btn-warning float-end'>Print Load list</button>}
                content={() => printRefLoadList.current}
              />

              <div className='d-none'>
                <PrintLoadList ref={printRefLoadList} sales={sales} />
              </div>

              <ReactToPrint
                trigger={() => <button className='btn btn-sm btn-info float-end mx-2'>Print Invoice</button>}
                content={() => printRefInvoice.current}
              />

              <div className='d-none'>
                <PrintInvoice ref={printRefInvoice} sales={sales} complains={complains} />
              </div>

              <h5><u className='titilescolor'>Sold Items Details</u></h5>
              <table className="table table-striped">
                <thead>
                  <tr className='theads'>
                    <td><strong>#</strong></td>
                    <th>Item</th>
                    <th>Label</th>
                    <th>QTY</th>
                    <th className='text-danger'>CN.QTY</th>
                    <th>Commision</th>
                    <th>T.Commision</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Sale Type</th>
                    <th>Status</th>
                    <th>Customer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems.map((sale, index) => (
                    <tr key={sale.id} className='theads'>
                      <td>{index + 1}</td>
                      {/* <td>{sale.itemid.item}</td> */}
                      <td>
                        {sale.itemid.item.includes("#1") ? sale.itemid.item.replace("#1", "creame") :
                        sale.itemid.item.includes("#2") ? sale.itemid.item.replace("#2", "pink") :
                        sale.itemid.item.includes("#3") ? sale.itemid.item.replace("#3", "orange") :
                        sale.itemid.item}
                      </td>
                      <td>{sale.itemid.label}</td>
                      <td>{sale.saleqty}</td>
                      <td className='text-danger'>
                        <input
                          className='form-control'
                          type="number"
                          value={sale.cnqty || 0} // Make sure it's a number
                          onChange={(e) => handleCNQtyChange(e, index)}
                          max={sale.saleqty}
                          min='0'
                        />
                      </td>
                      <td>{sale.commision}</td>
                      <td>{sale.commision * sale.saleqty}</td>
                      <td>{Number(sale.saleprice - sale.commision).toLocaleString()}</td>
                      <td>{Number((sale.saleqty - (sale.cnqty || 0)) * (sale.saleprice - sale.commision)).toLocaleString()}</td>
                      <td>{sale.saletype}</td>
                      <td>{sale.salestatus === 0 ?<p className='text-danger fw-bold'>Not sold</p> : <p className='text-success fw-bold'>Sold</p>}</td>
                      <td>{sale.customer}</td>
                      <td>
                        <i className="fa-solid fa-check fa-1x" onClick={() => handleSubmit(sale)} style={{ paddingLeft: '10px', color: 'green' }}></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              
                <>
                  <thead>
                    <tr className='theads'>
                      <td><strong>#</strong></td>
                      {!sales.some(sale => sale.saletype === 'wholesale') && (
                        <>
                          <th className='d-none'>
                            <select className="form-select" aria-label="Default select example" value={sellerId} onChange={handleSellerIdChange}>
                              <option disabled selected value="">Seller</option>
                              {sellers && sellers.map(seller => (
                                <option key={seller.id} value={seller.id}>{seller.sellername}</option>
                              ))}
                            </select>
                          </th>
                        </>
                        )}
                         <>
                          <th>
                            <label>Bank</label>
                            <input 
                              type="number" 
                              name='bank' 
                              min={0} 
                              value={bank} 
                              required 
                              className="form-control" 
                              id="bank" 
                              placeholder='Bank' 
                              onChange={handleBankChange} 
                            />
                          </th>
                          <th>
                            <label>Mpesa</label>
                            <input 
                              type="number" 
                              name='mpesa' 
                              min={0} 
                              value={mpesa} 
                              required 
                              className="form-control" 
                              id="mpesa" 
                              placeholder='Mpesa' 
                              onChange={handleMpesaChange} 
                            />
                          </th>
                          <th>
                            <label>Cash</label>
                            <input 
                              type="number" 
                              name='cash' 
                              min={0} 
                              value={cash} 
                              required 
                              className="form-control" 
                              id="cash" 
                              placeholder='Cash' 
                              onChange={handleCashChange} 
                            />
                          </th>
                          <th>
                            <label>Invoice NO</label>
                            <input 
                              type="text" 
                              name='invoicenumber' 
                              value={invoiceNumber} 
                              required 
                              className="form-control" 
                              id="invoicenumber" 
                              placeholder='Enter invoice number' 
                              onChange={handleInvoiceNumberChange} 
                            />
                          </th>
                          <th>
                            <label>ETR NO</label>
                            <input 
                              type="text" 
                              name='etrnumber' 
                              value={etrNumber} 
                              required 
                              className="form-control" 
                              id="etrnumber" 
                              placeholder='Enter ETR number' 
                              onChange={handleEtrNumberChange} 
                            />
                          </th>
                        </>
        
                      <th>
                        <button onClick={() => handleSellClick(invoiceNumber, bank, mpesa, cash, etrNumber, commission, sellerId, paymentmethod)} className='btn btn-success'>Sell</button>
                      </th>
                    </tr>
                  </thead>
                </> 

              {/* payments  */}
              <hr/>
              <h5><u>Payments</u></h5>
              <>
              <table className="table table-striped">
                <thead>
                  <tr className='theads'>
                    <td><strong>#</strong></td>
                    <th>Bank</th>
                    <th>Mpesa</th>
                    <th>Cash</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {payments.length > 0 ? (
                  payments.map((payment, index) => (
                    <tr key={payment.id} className='theads'>
                      <td>{index + 1}</td>
                      <td>{Number(payment.bank).toLocaleString()}</td>
                      <td>{Number(payment.mpesa).toLocaleString()}</td>
                      <td>{Number(payment.cash).toLocaleString()}</td>
                      <td>
                        <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(payment.id)} style={{ paddingLeft: '10px'}}></i>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>No payments made</td>
                  </tr>
                )}
                </tbody>
              </table>    
              </>
              
              <h5 className='mt-3 text-info'>Good Complain</h5>
              <tabel className="table table-striped">
                    <thead>
                      <tr className='theads'>
                        <th>Item</th>
                        <th>Label</th>
                        <th>QTY</th>
                        <th>Amount</th>
                        <th>Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {complains.map(item => (
                        <tr className='theads'>
                          <td> {item.itemname}</td>
                          <td> {item.itemlabel}</td>
                          <td> {item.qty}</td>
                          <td> {Number(item.amount).toLocaleString()}</td>
                          <td> {Number(item.amount * item.qty).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </tabel>
              
              <div className='boxshadowpaymentdetails mt-2'>
              {sales.length > 0 && sales[0].bank !== undefined && sales[0].mpesa !== undefined && sales[0].cash !== undefined && (
                <>
                  <h6 className='text-success mt-4'>Sub Total: {Number((sales[0].bank + sales[0].mpesa + sales[0].cash) - (sales[0].tcommision + totalGoodComplains)).toLocaleString()}</h6>
                </>
              )}
              {sales.length > 0 && sales[0].bank !== undefined && <h6 className='text-primary'>Bank: {Number(sales[0].bank).toLocaleString()}</h6>}
              {sales.length > 0 && sales[0].mpesa !== undefined && <h6 className='text-primary'>Mpesa: {Number(sales[0].mpesa).toLocaleString()}</h6>}
              {sales.length > 0 && sales[0].cash !== undefined && <h6 className='text-primary'>Cash: {Number(sales[0].cash).toLocaleString()}</h6>}
              {sales.length > 0 && sales[0].cash !== undefined && <h6 className='text-primary'>Commision: {Number(sales[0].tcommision).toLocaleString()}</h6>}
              <h6 className='text-info'>Good Complain: {Number(totalGoodComplains).toLocaleString()}</h6>
              {sales.length > 0 && sales[0].bank !== undefined && sales[0].mpesa !== undefined && sales[0].cash !== undefined && (
                <>
                  <h4 className='text-success'><u>Total: {Number(totalAmount).toLocaleString()}</u></h4>
                  <h6 className='text-dark'>Total Paid: {Number(sales[0].bank + sales[0].mpesa + sales[0].cash).toLocaleString()}</h6>
                  {/* <h6 className='text-danger'>Balance: {((sales[0].bank + sales[0].mpesa + sales[0].cash) - totalGoodComplains) - (sales[0].bank + sales[0].mpesa + sales[0].cash)}</h6> */}
                  <h6 className='text-danger'>Balance: {Number(balance).toLocaleString()}</h6>
                </>
              )}
              </div>

              <button className='btn btn-danger btn-sm mt-5' onClick={() => handleDeleteInvoice(id)}>Delete Invoice</button>

              <ToastContainer />
            </div>
          }
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
